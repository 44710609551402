@import 'fonts.css';
@import 'colors.css';

[class*='cto-icon-'] {
  line-height: 20px;
  font-family: carnival-toys-lib, fantasy;
  font-size: 22px;
  font-style: normal;
}
[class*='cto-icon-']::before {
  display: inline-block;
  height: 20px;
  transform: translateY(3px);
  box-sizing: border-box;
}
input::-webkit-input-placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  font-family: Raleway, sans-serif;
}
input::-moz-placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  font-family: Raleway, sans-serif;
}
input:-ms-input-placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  font-family: Raleway, sans-serif;
}
input::-ms-input-placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  font-family: Raleway, sans-serif;
}
body,
button,
input,
input::placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  font-family: Raleway, sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:focus {
  outline: 0;
}
body,
html {
  text-rendering: optimizeLegibility;
}
img {
  border: none;
}
section {
  display: block;
}
.cto-pageWrapper {
  padding-bottom: 40px;
}
.cto-contentWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.cto-mainPage {
  margin-inline-start: auto;
  margin-inline-end: auto;
  width: 100%;
}
.cto-btn {
  display: flex;
  width: 100%;
}
.cto-deleteLoader {
  margin-right: 8px;
}
.cto-btn__element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
}
.cto-btn__loadingWrap {
  margin-right: 10px;
}
.cto-btn__elementDisabled {
  opacity: 0.3;
  pointer-events: none;
}
.cto-btn__element[type='submit'] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  font-weight: 800;
  color: var(--black);
}
.cto-btn__element[href] {
  text-decoration: none;
}
.cto-btn__element [class*='cto-icon-'] {
  margin-right: 8px;
}
.cto-btn__element .cto-btn__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  font-weight: 800;
  color: var(--white);
  display: inline-block;
  width: auto;
  max-width: 100%;
  line-height: 20px;
}
.cto-btn__element i {
  height: 30px;
}
.cto-btn.-brand-01 .cto-btn__element {
  color: var(--white);
  background-color: var(--green);
}
.cto-btn.-brand-01 .cto-btn__element [class*='cto-icon-'] {
  color: var(--white);
}
.cto-btn.-brand-03 .cto-btn__element {
  color: var(--green);
  background-color: var(--white);
}
.cto-btn.-brand-03 .cto-btn__element [class*='cto-icon-'] {
  color: var(--green);
}
.cto-btn.-brand-03 .cto-btn__element .cto-btn__text {
  color: var(--green);
}
.cto-btn.-transparent .cto-btn__element {
  height: 24px;
  color: var(--black);
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}
.cto-btn.-transparent .cto-btn__element [class*='cto-icon-'] {
  color: var(--green);
}
.cto-btn.-transparent .cto-btn__element .cto-btn__text {
  color: var(--black);
}
.cto-btn.-small .cto-btn__element {
  height: 40px;
}
.cto-btnIcon {
  display: flex;
  width: 24px;
  height: 24px;
}
.cto-btnIcon__element {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--white);
  cursor: pointer;
}
.cto-btnIcon__element[type='button'] {
  border: none;
}
.cto-btnIcon__element[href] {
  text-decoration: none;
}
.cto-btnIcon.-brand-01 .cto-btnIcon__element [class*='cto-icon-'] {
  color: var(--green);
}
.cto-btnIcon.-brand-02 .cto-btnIcon__element [class*='cto-icon-'] {
  color: var(--black);
}
.cto-btn__back {
  margin-bottom: 30px;
  background-color: var(--white);
  color: var(--green);
  width: auto;
  padding-left: 0;
}
.cto-btn__back i {
  font-size: 15px;
  height: 24px;
}
.cto-btn__back .cto-btn__text {
  color: var(--black) !important;
}
.cto-input {
  display: flex;
  width: 100%;
  height: auto;
}
.cto-input__element:not([type='file']):not([type='checkbox']) {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  display: block;
  flex-shrink: 1;
  width: 100%;
  height: 40px;
  border: none;
  border: 1px solid var(--black);
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: none;
  padding: 0 16px;
  flex-grow: 1;
}
.cto-input__element::-webkit-input-placeholder,
.cto-input__element::placeholder,
.cto-input__element::-moz-placeholder,
.cto-input__element::-ms-input-placeholder,
.cto-input__element:-ms-input-placeholder {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--greyDark);
  font-weight: 400;
}
.cto-input__element[type='password'] {
  letter-spacing: 2px;
}
.cto-input__element[type='checkbox'] {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin: 4px 0 0;
}
.cto-input__element[type='file'] {
  width: 152px;
  opacity: 0;
  cursor: pointer;
}
.cto-input__label {
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  display: inline-block;
  width: auto;
  line-height: 20px;
  padding: 2px 0;
  margin-left: 15px;
}
.cto-input.-checkbox {
  z-index: 10;
  align-items: flex-start;
  min-height: 24px;
  position: relative;
}
.cto-input.-checkbox::before {
  z-index: 11;
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 1px solid var(--black);
  border-radius: 3px;
  background-color: var(--white);
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: 4px;
  left: 0;
}
.cto-input.-checkbox .cto-input__label::after {
  z-index: 11;
  line-height: 20px;
  font-family: carnival-toys-lib, fantasy;
  font-size: 13px;
  font-style: normal;
  color: var(--green);
  content: 'f';
  display: inline-block;
  width: 17px;
  height: 17px;
  transform: scale(0);
  transform-origin: center;
  transition: -webkit-transform 0.12s ease;
  transition: transform 0.12s ease;
  transition: transform 0.12s ease, -webkit-transform 0.12s ease;
  padding-left: 3px;
  pointer-events: none;
  position: absolute;
  top: 2px;
  left: 0;
}
.cto-input.-checkbox .cto-input__element:checked + .cto-input__label::after {
  transform: scale(1);
}
.cto-input.-file {
  z-index: 10;
  height: 40px;
  position: relative;
}
.cto-input.-file {
  z-index: 11;
  line-height: 20px;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  width: 152px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  background-color: var(--green);
  pointer-events: none;
}
.cto-input.-file.-button {
  width: 152px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  pointer-events: all;
  line-height: 16px
}
.cto-input__fileInput {
  display: none;
}
.cto-input.-file .cto-input__label {
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--greyDark);
  padding: 10px 0;
  margin-left: 20px;
}
.cto-formField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.cto-formField__label {
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  display: inline-block;
  width: 100%;
  height: auto;
  margin: 0 0 10px 0;
}
.cto-formField__errorMessage {
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--red);
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  max-height: 0;
  transform: translateY(-10px);
  transition: max-height 0.12s linear, padding-top 0.12s linear, opacity 0.12s linear, -webkit-transform 0.12s ease-out;
  transition: max-height 0.12s linear, padding-top 0.12s linear, transform 0.12s ease-out, opacity 0.12s linear;
  transition: max-height 0.12s linear, padding-top 0.12s linear, transform 0.12s ease-out, opacity 0.12s linear,
    -webkit-transform 0.12s ease-out;
  padding-top: 0;
  overflow: hidden;
  pointer-events: none;
}
.cto-select {
  z-index: 10;
  display: flex;
  width: 100%;
  height: 40px;
  position: relative;
}
.cto-select__element {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black);
  display: block;
  width: 100%;
  height: 40px;
  border: 1px solid var(--black);
  border-radius: 5px;
  background-color: var(--white);
  padding: 0 calc(39px + 15px) 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cto-select::after {
  z-index: 11;
  line-height: 20px;
  font-family: carnival-toys-lib, fantasy;
  font-size: 22px;
  font-style: normal;
  color: var(--green);
  content: 'b';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 38px;
  border-radius: 0 5px 5px 0;
  transform: rotate(0);
  transform-origin: center;
  padding: 7px 0 5px;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: 1px;
  right: 1px;
}
.cto-productsQuickAdd {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: var(--greyLight);
  padding: 30px;
}
.cto-productsQuickAdd__title {
  line-height: 21px;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 15px;
}
.cto-productsQuickAdd__wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}
.cto-productsQuickAdd__wrap .cto-formField {
  flex-basis: 0;
  flex-grow: 3;
  padding-right: 20px;
}
.cto-productsQuickAdd__wrap .cto-btn {
  flex-basis: 0;
  flex-grow: 1;
}
.cto-catalogSidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  background-color: var(--greyLight);
  padding: 30px 0;
}
.cto-catalogSidebar__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: none;
  padding: 0 30px 30px;
  margin: 0;
}
.cto-catalogSidebar__section::after {
  content: '';
  display: inline-block;
  width: 100%;
  max-width: 269px;
  height: 1px;
  background-color: var(--greyMedium);
  margin: 30px auto 0;
}
.cto-catalogSidebar__section.-borderless {
  padding-bottom: 20px;
}
.cto-catalogSidebar__section.-borderless::after {
  display: none;
}
.cto-catalogSidebar__section .cto-catalogSidebar__title {
  line-height: 21px;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}
.cto-catalogSidebar__section .cto-formField {
  margin-bottom: 20px;
}
.cto-catalogSidebar__section .cto-formField:last-child {
  margin-bottom: 0;
}
.cto-catalogSidebar__pageTotal {
  display: block;
  text-align: center;
}
.cto-downloadProdImagesCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--greyMedium);
  border-radius: 10px;
  padding: 30px;
}
.cto-downloadProdImagesCard__title {
  line-height: 21px;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  display: block;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.cto-downloadProdImagesCard__text {
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.cto-downloadProdImagesCard .cto-btn.-downloadProductImages {
  width: auto;
  margin: 0 auto 0 0;
}
.cto-catalogProductCard {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--greyMedium);
  padding: 20px 0;
}
.cto-catalogProductFirstCard {
  border-top: 1px solid var(--greyMedium);
}
.cto-catalogProductCard__data {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 20px;
}
.cto-catalogProductCard__data .cto-catalogProductCard__thumbnailWrap {
  margin-right: 10px;
}
.cto-catalogProductCard__data .cto-catalogProductCard__info {
  display: flex;
  flex-direction: column;
}
.cto-catalogProductCard__actions {
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-shrink: 1;
}
.cto-catalogProductCard__code {
  padding-top: 8px;
}
.cto-catalogProductCard__actions .cto-btn.-moveUp {
  margin-right: 30px;
}
.cto-page.-customCatalog .cto-page {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}
.cto-page.-customCatalog .cto-page__titleRow {
  width: 100%;
  padding: 0 20px;
  margin-top: 9px;
  margin-bottom: 26px;
}
.cto-page.-customCatalog .cto-page__title {
  line-height: 34px;
  font-size: 24px;
  font-weight: 700;
  color: var(--black);
}
.cto-page.-customCatalog .cto-page__productsCardTitleWrap {
  line-height: 21px;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 20px;
}
.cto-page.-customCatalog .cto-page__productsCardQtWrap {
  line-height: 21px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  text-transform: uppercase;
}
.cto-page.-customCatalog .cto-page__contentRow {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.cto-page.-customCatalog .cto-page__productsSection {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 65%;
}
.cto-page.-customCatalog .cto-page__addProductsWrap {
  padding-bottom: 20px;
}
.cto-page.-customCatalog .cto-page__sidebarSection {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 35%;
}
.cto-page.-customCatalog .cto-page__sidebarWrap {
  padding-bottom: 16px;
}
.cto-page.-customCatalog .cto-page__deleteProductsButtonWrap {
  display: flex;
  width: fit-content;
  margin-top: 10px;
}
.cto-page.-downloadImages .cto-page {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.cto-page.-downloadImages .cto-page__titleWrap {
  width: 100%;
  padding: 20px 0;
}
.cto-page.-downloadImages .cto-page__title {
  line-height: 34px;
  font-size: 24px;
  font-weight: 700;
  color: var(--black);
}
.cto-page.-downloadImages .cto-page__downloadComponentWrap {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 20px;
}
.cto-icon {
  display: inline-block;
  height: 20px;
  color: var(--green);
}
