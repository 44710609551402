@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Regular.eot');
  src: local(''), url('../assets/fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Regular.woff2') format('woff2'),
    url('../assets/fonts/Raleway-Regular.woff') format('woff'),
    url('../assets/fonts/Raleway-Regular.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Regular.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: italic;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Regular-Italic.eot');
  src: local(''), url('../assets/fonts/Raleway-Regular-Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Regular-Italic.woff2') format('woff2'),
    url('../assets/fonts/Raleway-Regular-Italic.woff') format('woff'),
    url('../assets/fonts/Raleway-Regular-Italic.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Regular-Italic.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Bold.eot');
  src: local(''), url('../assets/fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Bold.woff2') format('woff2'), url('../assets/fonts/Raleway-Bold.woff') format('woff'),
    url('../assets/fonts/Raleway-Bold.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Bold.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: italic;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Bold-Italic.eot');
  src: local(''), url('../assets/fonts/Raleway-Bold-Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Bold-Italic.woff2') format('woff2'),
    url('../assets/fonts/Raleway-Bold-Italic.woff') format('woff'),
    url('../assets/fonts/Raleway-Bold-Italic.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Bold-Italic.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Extrabold.eot');
  src: local(''), url('../assets/fonts/Raleway-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Extrabold.woff2') format('woff2'),
    url('../assets/fonts/Raleway-Extrabold.woff') format('woff'),
    url('../assets/fonts/Raleway-Extrabold.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Extrabold.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  font-style: italic;
  font-display: auto;
  src: url('../assets/fonts/Raleway-Extrabold-Italic.eot');
  src: local(''), url('../assets/fonts/Raleway-Extrabold-Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Raleway-Extrabold-Italic.woff2') format('woff2'),
    url('../assets/fonts/Raleway-Extrabold-Italic.woff') format('woff'),
    url('../assets/fonts/Raleway-Extrabold-Italic.ttf') format('truetype'),
    url('../assets/fonts/Raleway-Extrabold-Italic.svg#Raleway') format('svg');
}
